<template>
    <loader
        :isLoading="isLoading"
        :error="error"
        errorMessage="associations.showError"
    >
        <h1 class="text-center mb-4">{{ $t("associations.update.title") }}</h1>
        <v-card outlined color="primary" class="mt-2">
            <v-card-text>
                <v-form ref="form" @submit.prevent="onUpdate">
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                v-model="association.name"
                                :label="
                                    $t('associations.association.name') + '*'
                                "
                                :rules="requiredRule"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                type="number"
                                v-model="association.siret"
                                :label="$t('associations.association.siret')"
                                :rules="siretRule"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" sm="6" class="py-0">
                            <v-text-field
                                v-model="association.address.street"
                                :label="
                                    $t(
                                        'associations.association.address.street'
                                    )
                                "
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="2" class="py-0">
                            <v-text-field
                                v-model="association.address.post_code"
                                :label="
                                    $t(
                                        'associations.association.address.postCode'
                                    )
                                "
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4" class="py-0">
                            <v-text-field
                                v-model="association.address.city"
                                :label="
                                    $t(
                                        'associations.association.address.city'
                                    ) 
                                "
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-text-field
                        v-model="association.phone"
                        :label="$t('associations.association.phone')"
                        :rules="[phoneRule.phone(association.phone)]"
                    ></v-text-field>

                    <v-select
                        v-model="association.president"
                        :label="$t('associations.association.president')"
                        :items="members"
                        item-value="id"
                    >
                        <template v-slot:selection="{ item }">
                            {{ item.lastname }} {{ item.firstname }}
                        </template>
                        <template v-slot:item="{ item }">
                            {{ item.lastname }} {{ item.firstname }}
                        </template>
                    </v-select>
                    <v-select
                        v-model="association.director"
                        :label="$t('associations.association.director')"
                        :items="members"
                        item-value="id"
                    >
                        <template v-slot:selection="{ item }">
                            {{ item.lastname }} {{ item.firstname }}
                        </template>
                        <template v-slot:item="{ item }">
                            {{ item.lastname }} {{ item.firstname }}
                        </template>
                    </v-select>
                    <v-select
                        v-model="association.contact"
                        :label="$t('associations.association.contact')"
                        :items="members"
                        item-value="id"
                    >
                        <template v-slot:selection="{ item }">
                            {{ item.lastname }} {{ item.firstname }}
                        </template>
                        <template v-slot:item="{ item }">
                            {{ item.lastname }} {{ item.firstname }}
                        </template>
                    </v-select>

                    <v-row class="mb-2" justify="center">
                        <v-col cols="auto">
                            <v-btn
                                color="primary"
                                type="submit"
                                :loading="isLoadingUpdate"
                            >
                                {{ $t("button.update") }}
                            </v-btn>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn
                                :to="{
                                    name: 'Association',
                                    params: {
                                        associationId: associationId,
                                    },
                                }"
                            >
                                {{ $t("button.return") }}
                            </v-btn>
                        </v-col>
                    </v-row>
                    <p v-if="formIsInvalid" class="text-center warning--text">
                        {{ $t("form.error") }}
                    </p>
                    <p v-if="updateError" class="text-center error--text">
                        {{ $t("associations.update.error") }}
                    </p>
                </v-form>
            </v-card-text>
        </v-card>
    </loader>
</template>

<script>
import { mapState } from "vuex";
import Loader from "@/components/utils/Loader";

export default {
    components: {
        Loader,
    },

    props: {
        associationId: {
            type: [String, Number],
            required: true,
        },
    },

    data() {
        return {
            association: {
                address: {},
            },
            members: [],

            isLoading: true,
            error: false,

            formIsInvalid: false,

            isLoadingUpdate: false,
            updateError: false,
        };
    },

    mounted() {
        this.getAssociation();
    },

    computed: {
        ...mapState({
            requiredRule: (state) => state.utils.requiredRule,
            phoneRule: (state) => state.utils.phoneRule,
            siretRule: (state) => state.utils.siretRule,
        }),
    },

    methods: {
        getAssociation() {
            this.isLoading = true;
            this.error = false;

            this.$store
                .dispatch("ohme/getUpdateAssociation", this.associationId)
                .then((data) => {
                    this.association = data.data.association;
                    if (this.association.address == null) {
                        this.association.address = {};
                    }
                    this.members = data.data.members;

                    this.association.president = this.members.find((item) => {
                        return item.positions.includes("Président(e)");
                    });
                    if (this.association.president) {
                        this.association.president = this.association.president.id;
                    }
                    this.association.director = this.members.find((item) => {
                        return item.positions.includes("Directeur(trice)");
                    });
                    if (this.association.director) {
                        this.association.director = this.association.director.id;
                    }
                    this.association.contact = this.members.find((item) => {
                        return item.positions.includes("Correspondant(e)");
                    });
                    if (this.association.contact) {
                        this.association.contact = this.association.contact.id;
                    }

                    this.isLoading = false;
                })
                .catch((err) => {
                    if (
                        err !== undefined &&
                        err.response !== undefined &&
                        err.response.status == 403
                    ) {
                        this.$router.push({ name: "403" });
                    } else {
                        this.isLoading = false;
                        this.error = true;
                    }
                });
        },

        onUpdate() {
            if (this.$refs.form.validate()) {
                this.formIsInvalid = false;
                this.isLoadingUpdate = true;
                this.updateError = false;

                this.$store
                    .dispatch("ohme/updateAssociation", {
                        associationId: this.associationId,
                        form: this.association,
                    })
                    .then(() => {
                        this.$router.push({
                            name: "Association",
                            params: {
                                associationId: this.associationId,
                            },
                        });
                    })
                    .catch(() => {
                        this.isLoadingUpdate = false;
                        this.updateError = true;
                    });
            } else {
                this.formIsInvalid = true;
            }
        },
    },
};
</script>
